@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  min-height: 100%;
  margin: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure body takes at least the full viewport height */
}

#root {
  width: 100%; /* Take up full width */
  max-width: 960px; /* Adjust this as needed */
  min-height: 100vh; /* Minimum height to fill the viewport */
  display: flex; /* Use Flexbox for internal centering if needed */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
}
